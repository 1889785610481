import React, { useEffect, useState } from "react";
import {
  Grid,
  CardContent,
  Typography,
  Box,
  Paper,
  styled,
} from "@mui/material";
import { Male, Female, Face } from "@mui/icons-material";
import { getEmployeeByGender, getStudentByGender } from "./services/service";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "75%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
  borderRadius: 16,
  transition: "0.3s",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
  },
}));

const StatTypography = styled(Typography)(({ color }) => ({
  fontWeight: "bold",
  color: color || "#FFF",
  fontSize: "1rem",
}));

const StatBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(0.5),
}));

const IconBox = styled(Box)(({ color }) => ({
  display: "flex",
  alignItems: "center",
  "& svg": {
    marginRight: 4,
    color: color || "#FFF",
    fontSize: "1.2rem",
  },
}));

const CardDetails = ({ authToken }) => {
  const [students, setStudents] = useState({});
  const [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) return;
      try {
        const studentData = await getStudentByGender(authToken);
        const employeeData = await getEmployeeByGender(authToken);
        setStudents(studentData || {});
        setEmployeeData(employeeData || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [authToken]);

  const teachingData = employeeData.filter((data) => data?.type === "teaching");
  const nonTeachingData = employeeData.filter(
    (data) => data?.type === "nonTechnical"
  );
  const technical = employeeData.filter((data) => data?.type === "technical");

  const getDefaultStats = (data) => ({
    male: data?.male || 0,
    female: data?.female || 0,
    other: data?.other || 0,
    total: data?.total || data?.totalEmployees || 0,
  });

  const studentStats = getDefaultStats(students);
  const teachingStats = teachingData.length
    ? getDefaultStats(teachingData[0])
    : getDefaultStats({});
  const nonTeachingStats = nonTeachingData.length
    ? getDefaultStats(nonTeachingData[0])
    : getDefaultStats({});
  const technicalStats = technical.length
    ? getDefaultStats(technical[0])
    : getDefaultStats({});

  return (
    <Grid container spacing={2} sx={{ marginBottom: 0 }}>
      <Grid item xs={12} sm={6} md={3} maxHeight="70%">
        <StyledPaper
          elevation={5}
          sx={{
            backgroundColor: "white",
            color: "#E85C0D",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ padding: "0px", marginBottom: 0, flexGrow: 1 }}>
            <Box mt="5px">
              <StatBox>
                <IconBox color="#E85C0D">
                  <Male />
                  <Typography variant="body2" fontSize="0.8rem">
                    Male: {studentStats.male}
                  </Typography>
                </IconBox>
                <IconBox color="#E85C0D">
                  <Female />
                  <Typography variant="body2" fontSize="0.8rem">
                    Female: {studentStats.female}
                  </Typography>
                </IconBox>
                <IconBox color="#E85C0D">
                  <Face />
                  <Typography variant="body2" fontSize="0.8rem">
                    Others: {studentStats.other}
                  </Typography>
                </IconBox>
              </StatBox>
            </Box>
            <Typography
              textAlign="center"
              variant="body1"
              style={{
                marginTop: "5px",
                fontWeight: "bold",
                fontSize: "0.9rem",
              }}
            >
              Total: {studentStats.total}
            </Typography>
          </CardContent>

          {/* Full-width section at the bottom */}
          <Box
            sx={{
              width: "100%",
              borderRadius: "5px",
              backgroundColor: "#E85C0D",
              color: "#FFF",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <StatTypography variant="h6">Total Students</StatTypography>
          </Box>
        </StyledPaper>
      </Grid>

      {/* Teaching Staff Card */}
      <Grid item xs={12} sm={6} md={3}>
        <StyledPaper elevation={5} sx={{ color: "#821131" }}>
          <CardContent sx={{ padding: "0px", marginBottom: 0 }}>
            <Box mt="5px">
              <StatBox>
                <IconBox color="#821131">
                  <Male />
                  <Typography variant="body2" fontSize="0.8rem">
                    Male: {teachingStats.male}
                  </Typography>
                </IconBox>
                <IconBox color="#821131">
                  <Female />
                  <Typography variant="body2" fontSize="0.8rem">
                    Female: {teachingStats.female}
                  </Typography>
                </IconBox>
                <IconBox color="#821131">
                  <Face />
                  <Typography variant="body2" fontSize="0.8rem">
                    Others: {teachingStats.other}
                  </Typography>
                </IconBox>
              </StatBox>
              <Typography
                textAlign="center"
                variant="body1"
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                Total: {teachingStats.total}
              </Typography>
            </Box>
          </CardContent>
          <Box
            sx={{
              width: "100%",
              borderRadius: "5px",
              backgroundColor: "#821131",
              color: "#FFF",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <StatTypography variant="h6">Teaching Staff</StatTypography>
          </Box>
        </StyledPaper>
      </Grid>

      {/* Technical Staff Card */}
      <Grid item xs={12} sm={6} md={3}>
        <StyledPaper
          elevation={5}
          sx={{ backgroundColor: "", color: "#FABC3F" }}
        >
          <CardContent sx={{ padding: "0px", marginBottom: 0 }}>
            <Box mt="5px">
              <StatBox>
                <IconBox color="#FABC3F">
                  <Male />
                  <Typography variant="body2" fontSize="0.8rem">
                    Male: {technicalStats.male}
                  </Typography>
                </IconBox>
                <IconBox color="#FABC3F">
                  <Female />
                  <Typography variant="body2" fontSize="0.8rem">
                    Female: {technicalStats.female}
                  </Typography>
                </IconBox>
                <IconBox color="#FABC3F">
                  <Face />
                  <Typography variant="body2" fontSize="0.8rem">
                    Others: {technicalStats.other}
                  </Typography>
                </IconBox>
              </StatBox>
              <Typography
                textAlign="center"
                variant="body1"
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                Total: {technicalStats.total}
              </Typography>
            </Box>
          </CardContent>
          <Box
            sx={{
              width: "100%",
              borderRadius: "5px",
              backgroundColor: "#FABC3F",
              color: "#FFF",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <StatTypography textAlign="center" variant="h6">
              Technical Staff
            </StatTypography>{" "}
          </Box>
        </StyledPaper>
      </Grid>

      {/* Non-Teaching Staff Card */}
      <Grid item xs={12} sm={6} md={3}>
        <StyledPaper elevation={5} sx={{ color: "#C7253E" }}>
          <CardContent sx={{ padding: "0px", marginBottom: 0 }}>
            <Box mt="5px">
              <StatBox>
                <IconBox color="#C7253E">
                  <Male />
                  <Typography variant="body2" fontSize="0.8rem">
                    Male: {nonTeachingStats.male}
                  </Typography>
                </IconBox>
                <IconBox color="#C7253E">
                  <Female />
                  <Typography variant="body2" fontSize="0.8rem">
                    Female: {nonTeachingStats.female}
                  </Typography>
                </IconBox>
                <IconBox color="#C7253E">
                  <Face />
                  <Typography variant="body2" fontSize="0.8rem">
                    Others: {nonTeachingStats.other}
                  </Typography>
                </IconBox>
              </StatBox>
              <Typography
                textAlign="center"
                variant="body1"
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
              >
                Total: {nonTeachingStats.total}
              </Typography>
            </Box>
          </CardContent>
          <Box
            sx={{
              width: "100%",
              borderRadius: "5px",
              backgroundColor: "#C7253E",
              color: "#FFF",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <StatTypography textAlign="center" variant="h6">
              Non-Technical Staff
            </StatTypography>{" "}
          </Box>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default CardDetails;
