import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Paper, Typography, Grid, CircularProgress } from '@mui/material';
import { getEmployeeByType } from './services/service';

// Utility function to capitalize the first letter
const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const TeachingStaffType = ({ authToken }) => {
  const [staffData, setStaffData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEmployeeByType(authToken);
        const permanentData = data.find(item => item.title === 'Permanent');
        setStaffData(permanentData || {});
      } catch (error) {
        console.error('Error fetching data:', error);
        setStaffData({});
      } finally {
        setLoading(false);
      }
    };

    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  const generateChartOption = () => {
    if (!staffData || Object.keys(staffData).length === 0) {
      return {};
    }

    const positions = [
      'professor',
      'reader',
      'lecturer',
      'assistantLecturer',
      'teachingAssistant',
      'instructor',
      'others'
    ];
    
    const capitalizedPositions = positions.map(capitalizeFirstLetter);
    const colors = ["#E85C0D", "#FABC3F", "#821131"]; // Specified colors for Male, Female, and Others

    const seriesData = [];
    const xAxisData = [];

    positions.forEach((position, index) => {
      const maleCount = staffData[position]?.male || 0;
      const femaleCount = staffData[position]?.female || 0;
      const othersCount = staffData[position]?.others || 0;

      if (maleCount > 0 || femaleCount > 0 || othersCount > 0) {
        xAxisData.push(capitalizedPositions[index]);
        seriesData.push(maleCount, femaleCount, othersCount);
      }
    });

    return {
      tooltip: {
        trigger: 'item',
        axisPointer: { type: 'shadow' },
      },
      legend: {
        data: ['Male', 'Female', 'Others'],
        textStyle: { color: '#333' },
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#333',
          interval: 0,
          rotate: 30,
        },
        name: "Type", // Title for x-axis
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: true,
        },
        axisLabel: { color: '#333' },
        name: "Total Staff", // Title for y-axis
      },
      series: [
        {
          name: 'Male',
          type: 'bar',
          stack: 'staff',
          itemStyle: { color: colors[0] }, // Male color
          data: seriesData.filter((_, idx) => idx % 3 === 0), // Males
        },
        {
          name: 'Female',
          type: 'bar',
          stack: 'staff',
          itemStyle: { color: colors[1] }, // Female color
          data: seriesData.filter((_, idx) => idx % 3 === 1), // Females
        },
        {
          name: 'Others',
          type: 'bar',
          stack: 'staff',
          itemStyle: { color: colors[2] }, // Color for Others
          data: seriesData.filter((_, idx) => idx % 3 === 2), // Others
        },
      ],
    };
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={5} sx={{ padding: '20px' }}>
          <Typography variant='body1' align="center" fontWeight={900} style={{ padding: '1rem' }}>
            Number of Permanent Teaching Staff
          </Typography>
          {staffData && Object.keys(staffData).length > 0 ? (
            <ReactECharts option={generateChartOption()} style={{ height: '400px' }} />
          ) : (
            <Typography variant="body1" align="center" color="textSecondary">
              No data available
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TeachingStaffType;
