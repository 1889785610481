import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Grid, Paper, Typography } from '@mui/material';
import { getStudentByFacultyAndEthnicity } from './services/service';

const FacultyCasteChart = ({ authToken }) => {
  const [studentsData, setStudentsData] = useState([]);

  useEffect(() => {
    if (!authToken) return;
    const fetchData = async () => {
      try {
        const data = await getStudentByFacultyAndEthnicity(authToken);
        setStudentsData(data || []); // Ensure data is an array
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [authToken]);

  const generateChartOption = () => {
    const categories = ['Dalit', 'Janajati', 'Madhesi', 'Tharu', 'EDJ'];
    const programs = studentsData.length ? studentsData.map(item => item.title) : [];

    // Creating series data for each category, filtering out zero values
    const seriesData = categories.map(category => {
      const categoryData = studentsData.map(item => item[category.toLowerCase()] || 0);
      const nonZeroData = categoryData.filter(value => value > 0);
      
      // Only include the series if there are non-zero values
      if (nonZeroData.length > 0) {
        return {
          name: category,
          type: 'bar',
          barWidth: 35,
          data: nonZeroData,
          itemStyle: {
            color: category === 'Dalit' ? '#E85C0D' :
                   category === 'Janajati' ? '#821131' :
                   category === 'Madhesi' ? '#FABC3F' :
                   category === 'Tharu' ? '#C7253E' :
                   category === 'EDJ' ? '#22577A' : '#22577A',
          },
          label: {
            show: true,
            position: 'inside',
            color: '#fff',
          },
        };
      }
      return null; // Return null for series that do not have non-zero values
    }).filter(Boolean); // Remove nulls from the seriesData

    // Filter programs based on non-zero data
    const filteredPrograms = programs.filter((_, index) =>
      seriesData.some(series => series.data[index] > 0)
    );

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: seriesData.map(series => series.name), // Legend only includes series with data
        textStyle: {
          color: '#2E294E',
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: [
        {
          type: 'category',
          data: filteredPrograms,
          axisLabel: {
            color: '#333',
          },
          name: "Faculties", // Title for x-axis

        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLine: {
            show: true,
          },
          name: "Total Students", // Title for x-axis
          axisLabel: {
            color: '#333',
          },
        },
      ],
      series: seriesData,
    };
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={5} sx={{ padding: '20px' }}>
          <Typography variant='body1' align="center"               fontWeight={900}
 style={{ padding: '1rem' }}>
            Students By Faculty and Caste Ethnicity
          </Typography>
          {studentsData.length > 0 ? (
            <ReactECharts
              option={generateChartOption()}
              style={{ height: '400px' }}
              opts={{ renderer: 'canvas' }} // Use canvas renderer for better performance
            />
          ) : (
            <Typography variant="body1" align="center" color="textSecondary">
              No data available
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default FacultyCasteChart;
