import React from "react";
import { Grid } from "@mui/material";
import CardDetails from "../../components/dashboard/CardDetails";
import FacultyGenderChart from "../../components/dashboard/FacultyGenderChart";
import FacultyCasteChart from "../../components/dashboard/FacultyCasteChart";
import { Typography } from "@mui/material";
import TeachingStaffType from "../../components/dashboard/TeachingStaffTypeChart";
import EmployeePieChart from "../../components/dashboard/EmployeePieChart";
import NonTeachingStaff from "../../components/dashboard/NonTeachingStaff";
import NonTeachingStaffType from "../../components/dashboard/NonTeachingStaffTypeChart";
import UnivesityTable from "../../components/dashboard/Tables/UnivesityTable";
import UgcTable from "../../components/dashboard/Tables/UgcTable";
import { useSelector } from "react-redux";
import TechnicalStaff from "../../components/dashboard/TechnicalStaff";

const Dashboard = () => {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;

  if (!currentUser) {
    return <Typography>Loading...</Typography>; // Show a loader until currentUser is loaded
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mt=".5rem">
        <CardDetails authToken={currentUser?.tokenString} />
      </Grid>

      <Grid item xs={12}>
        <FacultyGenderChart authToken={currentUser?.tokenString} />
      </Grid>

      <Grid item xs={12} >
        <FacultyCasteChart authToken={currentUser?.tokenString} />
      </Grid>

      <Grid item xs={12} >
        {roleName === "SuperAdmin" ? (
          <UgcTable authToken={currentUser?.tokenString} />
        ) : roleName === "UniSuperAdmin" ? (
          <UnivesityTable authToken={currentUser?.tokenString} />
        ) : null}
      </Grid>

      <Grid item xs={12} display="flex" gap={2}>
        <Grid item xs={12} md={12}>
          <EmployeePieChart authToken={currentUser?.tokenString} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TechnicalStaff authToken={currentUser?.tokenString}/>
        </Grid>
        <Grid item xs={12} md={12}>
          <NonTeachingStaff authToken={currentUser?.tokenString} />
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" gap={2}>

      <Grid item xs={12}>
        <TeachingStaffType authToken={currentUser?.tokenString} />
      </Grid>

      <Grid item xs={12}>
        <NonTeachingStaffType authToken={currentUser?.tokenString} />
      </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
