import React, { useEffect, useState, useContext, createContext } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  Box,
  MenuItem,
  InputLabel,
  Button,
  FormControl
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { getBatch, getCampus, getFaculty, getFiscalYear, getLevel, getProgram } from "../../../services/services";
import { getStudentById } from "../../../services/employeeService";

const EditStudentRegContext = createContext();

const EditStudentRegProvider = ({ children }) => {
  const methods = useForm();
  const [editRegistrationInfo, setEditRegistrationInfo] = useState({
    campusId: 0,
    levelId: 0,
    facultyId: 0,
    programId: 0,
    admissionYear: '',
    completionYear: '',
    dateOfEnrollment: "",
    fiscalYearId: 0,
  });

  const onChange = (name, value) => {
    setEditRegistrationInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <EditStudentRegContext.Provider value={{ ...methods, editRegistrationInfo, onChange }}>
      {children}
    </EditStudentRegContext.Provider>
  );
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000",
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const EditStudentRegistrationInfo = ({ handleNext, handleBack, id }) => {
  console.log(id)
  const { control, handleSubmit, setValue, formState: { errors } } = useContext(EditStudentRegContext);

  const [campusData, setCampusData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [facultyData, setFacultyData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [fiscalYear, setFiscalYear] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [defaultFiscal, setDefaultFiscal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const campusData = await getCampus();
        const levelData = await getLevel();
        const facultyData = await getFaculty();
        const programData = await getProgram();
        const fiscalYearData = await getFiscalYear();
        const batch = await getBatch(); // Await getBatch() to ensure data is fetched

        setFiscalYear(fiscalYearData);
        setLevelData(levelData);
        setCampusData(campusData);
        setFacultyData(facultyData);
        setProgramData(programData);
        setBatchData(Array.isArray(batch) ? batch : []);

        const activeFiscalYear = fiscalYearData.find(data => data && data.activeFiscalYear === true);
        if (activeFiscalYear) {
          setDefaultFiscal(activeFiscalYear.id);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchDatas = async () => {
      try {
        const studentData = await getStudentById(id);
        setValue('campusId', studentData.campusId);
        setValue('levelId', studentData.levelId);
        setValue('facultyId', studentData.facultyId);
        setValue('programId', studentData.programId);
        setValue('admissionYear', studentData.admissionYear);
        setValue('completionYear', studentData.complitionYear);
        setValue('dateOfEnrollment', studentData.dateOfEnrollment);
        setValue('fiscalYearId', studentData.fiscalYearId);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    fetchDatas();
  }, [id, setValue]);
  const onChange = useContext(EditStudentRegContext).onChange;

  const onSubmit = (data) => {
    const { campusId, levelId, facultyId, programId, admissionYear, completionYear, dateOfEnrollment, fiscalYearId } = data;
    onChange("campusId", campusId);
    onChange("levelId", levelId);
    onChange("facultyId", facultyId);
    onChange("programId", programId);
    onChange("admissionYear", admissionYear);
    onChange("completionYear", completionYear);
    onChange("dateOfEnrollment", dateOfEnrollment);
    onChange("fiscalYearId", fiscalYearId);

    handleNext();
  };

  return (
    <Grid container component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <Typography textAlign='center' variant="subtitle1" sx={{ color: "#636363", mt: '.5rem' }}>
            Registration
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <FormControl size="small" fullWidth>
              <InputLabel id="levelId">Level Name</InputLabel>
              <Controller
                name="levelId"
                control={control}
                defaultValue="" 
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    labelId="levelId"
                    id="levelId"
                    label="Level Name"
                    fullWidth
                  >
                    <MenuItem value="" disabled>Select the Levels</MenuItem>
                    {levelData.map((data) => (
                      <MenuItem key={data.id} value={data.id}>{data.levelName}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl size="small" fullWidth>
              <InputLabel id="facultyId">Faculty Name</InputLabel>
              <Controller
                name="facultyId"
                control={control}
                rules={{ required: "Required" }}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    id="facultyId"
                    size="small"
                    label="Faculty Name"
                    fullWidth
                    error={!!errors.facultyId}
                    helperText={errors.facultyId ? "Required" : ""}
                  >
                    {facultyData.map((data) => (
                      <MenuItem key={data.id} value={data.id}>{data.facultyName}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl size="small" fullWidth>
              <InputLabel id="programId">Program</InputLabel>
              <Controller
                name="programId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    labelId="programId"
                    id="programId"
                    label="Program"
                    fullWidth
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {programData.map((data) => (
                      <MenuItem key={data.id} value={data.id}>{data.programName}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1.75}>
            <FormControl size="small" fullWidth>
              <InputLabel required>Admission Year</InputLabel>
              <Controller
                name="admissionYear"
                control={control}
                defaultValue=""
                rules={{
                  pattern: {
                    value: /^\d{0,4}$/,
                    message: "Please enter only numeric values with maximum length of four digits",
                  },
                }}
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    id="admissionYear"
                    size="small"
                    label="Admission Year"
                    fullWidth
                    InputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        if (e.target.value.length > 4) {
                          e.target.value = e.target.value.slice(0, 4);
                        }
                      },
                    }}
                  >
                    {batchData.map((data) => (
                      <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1.75}>
            <FormControl size="small" fullWidth>
              <InputLabel required>Completion Year</InputLabel>
              <Controller
                name="completionYear"
                control={control}
                defaultValue=""
                rules={{
                  pattern: {
                    value: /^\d{0,4}$/,
                    message: "Please enter only numeric values with maximum length of four digits",
                  },
                }}
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    id="completionYear"
                    size="small"
                    label="Completion Year"
                    fullWidth
                    InputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        if (e.target.value.length > 4) {
                          e.target.value = e.target.value.slice(0, 4);
                        }
                      },
                    }}
                  >
                    {batchData.map((data) => (
                      <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="dateOfEnrollment"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="dateOfEnrollment"
                  size="small"
                  type="date"
                  label="Date Of Enrollment"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.dateOfEnrollment}
                  helperText={errors.dateOfEnrollment ? "Required" : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="fiscalYearId">Fiscal Year</InputLabel>
              <Controller
                name="fiscalYearId"
                control={control}
                defaultValue={defaultFiscal || 0}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="fiscalYearId"
                    size="small"
                    label="Fiscal Year"
                    fullWidth
                    required
                    error={!!errors.fiscalYearId}
                    helperText={errors.fiscalYearId ? "Fiscal year is required" : ""}
                  >
                    {fiscalYear.map((data) => (
                      <MenuItem key={data.id} value={data.id}>{data.yearNepali}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="flex-start">
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              variant="standard"
              size="small"
              onClick={() => handleBack()} // use handleBack directly
              sx={{ color: "#003285", marginBottom: "10px", marginLeft: "10px" }}
              startIcon={<ChevronLeftRoundedIcon />}
            >
              Back
            </Button>
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{ bgcolor: "#003285", marginBottom: "10px", marginLeft: "10px" }}
              endIcon={<ChevronRightRoundedIcon />}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { EditStudentRegProvider, EditStudentRegContext };
export default EditStudentRegistrationInfo;
