import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { FacultyMgmt, LevelMgmt, ProgramMgmt, SubjectMgmt } from './components';
import UniversityList from './pages/university/UniversityList.jsx'
import UniversityRegister from './pages/university/UniversityRegister.jsx';
import Login from './pages/auth/login.jsx';
import Register from './pages/auth/register.jsx';
import Dashboard from './pages/dashboard/dashboard.jsx';
import CampusRegister from './pages/campus/CampusRegister.jsx';
import StudentStepper from './components/studentRegistration/StudentStepper.jsx';
import CampusList from './pages/campus/CampusList.jsx';
import ArthikBarsa from './components/setup/arthikBarsa/ArthikBarsa.jsx';
import ResponsiveAppBar from './components/appBar/AppBar.jsx';
import NotFound from './pages/error/Noroute.jsx';
import { Box } from '@mui/material';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from './pages/profile/Profile.jsx';
import StudentInfoDetail from './components/report/university/StudentInfoDetail.jsx';
import EmployeeRegister from './components/employeeRegister/EmployeeRegister.jsx';
import Footer from './components/footer/Footer.jsx';
import TeacherDetails from './components/report/university/TeacherDetails.jsx';
import NonTeachingDetails from './components/report/university/NonTeachingDetails.jsx';
import StudentList from './pages/students/StudentList.jsx';
import StudentAcademicInfo from './components/studentRegistration/StudentAcademicInfo.jsx';
import EmployeeList from './pages/employee/EmployeeList.jsx';
import PrivateRoute from './components/privateRoute/PrivateRoute.jsx';
import StudentSummary from './components/report/StudentSummary.jsx';
import StudentByDistrict from './components/report/StudentByDistrict.jsx';
import TeachingStaffSummary from './components/report/TeachingStaffSummary.jsx';
import NonTeachingStaffSummary from './components/report/NonTeachingStaffSummary.jsx';
import './app.css'
import TeachingStaff from './pages/employee/TeachingStaff.jsx';
import TechnicalStaff from './pages/employee/TechnicalStaff.jsx';
import NonTechnicalStaff from './pages/employee/NonTechnicalStaff.jsx';
import ConstituentCampus from './pages/campus/ConstituentCampus.jsx';
import CommunityCampus from './pages/campus/CommunityCampus.jsx';
import PrivateCampus from './pages/campus/PrivateCampus.jsx';
import Main from './esewa/Main.js';
import Success from './esewa/Success.js';
import Failure from './esewa/Failure.js';
import EnrollmentForm from './components/setup/enrollment/EnrollmentForm.jsx';
import FeeSetup from './components/setup/feeSetup/FeeSetup.jsx';
import EnrollCourse from './components/setup/enrollment/EnrollCourse.jsx';
import HomeRedirect from './components/privateRoute/HomeRedirect.jsx';
import { useSelector } from 'react-redux';
import AddFaculties from './components/addingComponents/AddingFaculties.jsx';
import StudentsDetails from './pages/students/ForVerificationStudents.jsx';
import VerifiedStudentList from './pages/students/VerifiedStudentList.jsx'
import BatchMgmt from './components/setup/batch/CreateBatch.jsx';
import UpgradeAcademics from './pages/students/UpgradeAcademics.jsx';
import EthnicGroup from './components/setup/ethnicGroup/EthnicsGroup.jsx';
import FeeType from './components/setup/feeSetup/FeeType.jsx';
import RegisterUser from './components/settings/RegisterUser.jsx';
import LandMgmt from './components/setup/land/LandMgmt.jsx';
import BuildingMgmt from './components/setup/Building/BuildingMgmt.jsx';
import LabMgmt from './components/setup/lab/LabMgmt.jsx';
import HostelMgmt from './components/setup/Hostel/HostelMgmt.jsx';
import FacilityMgmt from './components/setup/RegisterFacility/RegisterMgmt.jsx';
import LibraryMgmt from './components/setup/library/LibraryMgmt.jsx';
import EquipMgmt from './components/setup/Equipment/EquipmentMgmt.jsx';
import PasswordReset from './pages/auth/PasswordReset.jsx';
import NewPassword from './pages/auth/NewPassword.jsx';
import StudentHome from './components/student/StudentHome.jsx';
import AdminLogin from './pages/auth/AdminLogin.jsx';


function AdminRoute() {
  const { currentUser } = useSelector(state => state.user);
  console.log(currentUser)

  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  console.log(roleName)

  return (
    <Box sx={{
      margin: 0, padding: 0,
      minHeight: '100vh',
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={5}>
        <ResponsiveAppBar />
      </Box>
      <Box flexGrow={2} margin='30px 20px 20px 20px'>
        <Routes>
          {/* Redirect to /open-enroll if not logged in */}
          <Route element={<PrivateRoute />}>
            {
              roleName === 'Student' ? (
                <Route path="/" element={<StudentHome />} >
                <Route path='student-home' element={<StudentHome />} />
                </Route>
              ) : (
                <>
                <Route path="/" element={<Dashboard />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/student-list' element={<StudentList />} />
                  <Route path='/add-user' element={<RegisterUser />} />
                  <Route path='/employee-list' element={<EmployeeList />} />
                  <Route path='/teaching-staff' element={<TeachingStaff />} />
                  <Route path='/technical-staff' element={<TechnicalStaff />} />
                  <Route path='/non-technical-staff' element={<NonTechnicalStaff />} />
                  <Route path='/academic-info/:id' element={<StudentAcademicInfo />} />
                  <Route path='/land-management' element={<LandMgmt />} />
                  <Route path='/building-management' element={<BuildingMgmt />} />
                  <Route path='/lab-management' element={<LabMgmt />} />
                  <Route path='/hostel-management' element={<HostelMgmt />} />
                  <Route path='/land-management' element={<LandMgmt />} />
                  <Route path='/facility-register' element={<FacilityMgmt />} />
                  <Route path='/library-management' element={<LibraryMgmt />} />
                  <Route path='/land-management' element={<LandMgmt />} />
                  <Route path='/equipment-management' element={<EquipMgmt />} />
                  <Route path='/student-information-detail' element={<StudentInfoDetail />} />
                  <Route path='/student-summary-detail' element={<StudentSummary />} />
                  <Route path='/student-by-district' element={<StudentByDistrict />} />
                  <Route path='/teaching-staff-summary' element={<TeachingStaffSummary />} />
                  <Route path='/non-teaching-staff-summary' element={<NonTeachingStaffSummary />} />
                  <Route path='/teacher-detail' element={<TeacherDetails />} />
                  <Route path='/non-teaching-details' element={<NonTeachingDetails />} />
                  <Route path="/faculty-management" element={<FacultyMgmt />} />
                  <Route path="/level-management" element={<LevelMgmt />} />
                  <Route path="/program-management" element={<ProgramMgmt />} />
                  <Route path="/subject-management" element={<SubjectMgmt />} />
                  <Route path="/batch-management" element={<BatchMgmt />} />
                  <Route path="/ethnic-group-management" element={<EthnicGroup />} />
                  <Route path="/university-register" element={<UniversityRegister />} />
                  <Route path="/campus-register" element={<CampusRegister />} />
                  <Route path="/university-list" element={<UniversityList />} />
                  <Route path='/campus-list' element={<CampusList />} />
                  <Route path='/constituent-campus' element={<ConstituentCampus />} />
                  <Route path='/community-campus' element={<CommunityCampus />} />
                  <Route path='/private-campus' element={<PrivateCampus />} />
                  <Route path="/student-register" element={<StudentStepper />} />
                  <Route path='/employee-register' element={<EmployeeRegister />} />
                  <Route path='/enrollment/:id' element={<EnrollmentForm />} />
                  <Route path='/fee-setup' element={<FeeSetup />} />
                  <Route path='/fee-type' element={<FeeType />} />
                  <Route path='/upgrade-academics' element={<UpgradeAcademics />} />
                  <Route path='/college-programs' element={<AddFaculties />} />
                  <Route path='/verified-students' element={<VerifiedStudentList />} />
                  <Route path='/student-verification/:id' element={<StudentsDetails />} />
                </>
              )
            }

          </Route>
          <Route path="/forgot-password" element={<PasswordReset />} />
          <Route path="/reset-password/:email/:token" element={<NewPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path='/open-enroll' element={<EnrollCourse />} />
          <Route path='/arthikBarsa' element={<ArthikBarsa />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<Login />} />
          <Route exact path="/esewa" element={<Main />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failed" element={<Failure />} />
        </Routes>
        <ToastContainer />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Footer />
      </Box>
    </Box >
  );
}

export default AdminRoute;

