import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { EditStudentInfoContext } from './EditGeneralInfo';
import { EditStudentAddressContext } from './EditStudentAddressInfo';
import { EditStudentGuardianContext } from './EditGuardianInfo';
// import { EditStudentAcademicContext } from './EditStudentAcademicsInfo';
import { EditStudentRegContext } from './EditStudentRegistration';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
const backendUrl = process.env.REACT_APP_BACKEND_URL; 
const EditReviewDetails = ({ handleBack, id, onClose, onUpdate, onRegisterSuccess }) => {
  const { editStudentInfo } = useContext(EditStudentInfoContext);
  const { editStudentAddress } = useContext(EditStudentAddressContext);
  const { editGuardianInfo } = useContext(EditStudentGuardianContext);
  const [loading, setLoading] = useState(false)

  // const { editAcademicInfo } = useContext(EditStudentAcademicContext)
  const { editRegistrationInfo } = useContext(EditStudentRegContext)
  const { currentUser } = useSelector((state) => state.user);
  
  let universityId;
  let campusId;

  if (currentUser.type === 'Uni') {
    universityId = currentUser.institution.id;
  } else if (currentUser.type === 'college') {
    campusId = currentUser.institution.id;
  }

  const handleRegister = async () => {
    setLoading(true); // Start loading

      const formData = new FormData();
      formData.append('id', id); 
      formData.append('campusId',campusId || 0); 
      formData.append('universityId', universityId || 0); 
      formData.append('citizenIssueDist', editStudentInfo.issuedDist);
      formData.append('nidno', editStudentInfo.nidNo);
      formData.append('levelId', editRegistrationInfo.levelId);
      formData.append('facultyId', editRegistrationInfo.facultyId);
      formData.append('programId', editRegistrationInfo.programId);
      formData.append('admissionYear', editRegistrationInfo.admissionYear);
      formData.append('complitionYear', editRegistrationInfo.completionYear);
      formData.append('nepaliName', editStudentInfo.nepaliName);
      formData.append('firstName', editStudentInfo.firstName);
      formData.append('middleName', editStudentInfo.middleName);
      formData.append('lastName', editStudentInfo.lastName);
      formData.append('phoneNumber', editStudentInfo.phone);
      formData.append('doBBS', editStudentInfo.dobBs);
      formData.append('doBAD', editStudentInfo.dobAd);
      formData.append('gender', editStudentInfo.gender);
      formData.append('ethnicity', editStudentInfo.ethnicity);
      formData.append('nationality', editStudentInfo.nationality);
      formData.append('disabilityStatus', editStudentInfo.disabilityStatus);
      formData.append('disabilityType', editStudentInfo.disabilityType);
      formData.append('email', editStudentInfo.email);
      formData.append('citizenshipNo', editStudentInfo.citizenshipNo);
      formData.append('nidNo', editStudentInfo.nidNo); 
      formData.append('pProvince', editStudentAddress.pProvince);
      formData.append('pDistrict', editStudentAddress.pDistrict);
      formData.append('pLocalLevel', editStudentAddress.pLocalLevel);
      formData.append('pWardNo', editStudentAddress.pWardNo);
      formData.append('pBlockNo', 0);
      formData.append('pHouseNo', 0);
      formData.append('pLocality', editStudentAddress.pTole);
      formData.append('tProvince', editStudentAddress.tProvince);
      formData.append('tDistrict', editStudentAddress.tDistrict);
      formData.append('tLocalLevel', editStudentAddress.tLocalLevel);
      formData.append('tWardNo', editStudentAddress.tWardNo);
      formData.append('tBlockNo', 0);
      formData.append('tHouseNo', 0);
      formData.append('tLocality', editStudentAddress.tTole);
      formData.append('fatherName', editGuardianInfo.fatherName);
      formData.append('fOccupation', editGuardianInfo.fatherOccupation);
      formData.append('fatherPhoneNo', editGuardianInfo.fatherPhoneNo);
      formData.append('fatherEmail', editGuardianInfo.fatherEmail);
      formData.append('motherName', editGuardianInfo.motherName);
      formData.append('mOccupation', editGuardianInfo.motherOccupation);
      formData.append('motherPhoneNo', editGuardianInfo.motherPhoneNo);
      formData.append('motherEmail', editGuardianInfo.motherEmail);
      formData.append('guardianName', editGuardianInfo.guardianName);
      formData.append('gOccupation', editGuardianInfo.guardianOccupation);
      formData.append('guardianPhone', editGuardianInfo.guardianPhone);
      formData.append('gAddress', editGuardianInfo.address);
      formData.append('gEmail', editGuardianInfo.guardianEmail);
      formData.append('fiscalYearId', editRegistrationInfo.fiscalYearId);
      try {
  
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
  
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data'
        }
      };
  
      const response = await axios.patch(`${backendUrl}/Student/${id}`, formData, config);
      console.log('Response:', response);
  
      if (response.status === 200) {
       
        onUpdate()
      } else {
        throw new Error('Failed to update student');
      }
      onRegisterSuccess(id); // Pass the ID to the parent component

    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error("Student already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };
  
   
  const StudentGeneralInfo = editStudentInfo ? [
    { name: 'First Name:', detail: editStudentInfo.firstName },
    { name: 'Middle Name:', detail: editStudentInfo.middleName },
    { name: 'Last Name:', detail: editStudentInfo.lastName },
    { name: 'Date Of Birth(BS):', detail: editStudentInfo.dobBs },
    { name: 'Phone Number:', detail: editStudentInfo.phone },
    { name: 'Email:', detail: editStudentInfo.email },
    { name: 'Date Of Birth(AD):', detail: editStudentInfo.dobAd },
    { name: 'Gender:', detail: editStudentInfo.gender },
    { name: 'Ethnicity:', detail: editStudentInfo.ethnicity },
    { name: 'Nationality:', detail: editStudentInfo.nationality },
    { name: 'Disability Status:', detail: editStudentInfo.disabilityStatus },
    { name: 'Disability Type:', detail: editStudentInfo.disabilityType },
    { name: 'Citizenship No:', detail: editStudentInfo.citizenshipNo },
    { name: 'Citizenship issued District:', detail: editStudentInfo.issuedDist },
    { name: 'NID No:', detail: editStudentInfo.nidNo },
  ] : [];

  
  // const StudentAcademicInfo = editAcademicInfo ? [
  //   { name: 'Education Level:', detail: editAcademicInfo.educationLevel },
  //   { name: 'Institytion Name:', detail: editAcademicInfo.institutionName },
  //   { name: 'Passed Year:', detail: editAcademicInfo.passedYear },
  //   { name: 'Obtained Mark:', detail: editAcademicInfo.obtainedMark },
  //   { name: 'Registration Number:', detail: editAcademicInfo.regdNo },
  //   { name: 'Board Of University:', detail: editAcademicInfo.boardOfUniversity },
  // ] : [];

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ textAlign: "center", color: "#2A629A" }}
      >
        Review Details
      </Typography>
      <Grid container spacing={1}>
        {/* University Details */}
        <Grid item xs={12} md={12}>
          <Box border='1px solid #c2c2c2' borderRadius='10px' position='relative' paddingBottom='15px'>
            <Typography variant="subtitle1" display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'>
              General Info
            </Typography>
            <Grid container paddingLeft='2.5rem' justifyContent="flex-start">
              {StudentGeneralInfo.map((details, index) => (
                <Grid item xs={5} key={index}>
                  <Stack direction="row" spacing={1} sx={{ width: '100%', mb: 1 }}>
                    <Typography variant="body1" color="text.secondary">
                      {details.name}
                    </Typography>
                    <Typography variant="body2">{details.detail || '-'}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* Contact Person Details */}
         {/* Address Info */}
         <Grid item xs={12} md={12}>
         <Box border='1px solid #c2c2c2' borderRadius='10px' position='relative' paddingBottom='15px'>
            <Typography variant="subtitle1" display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'>
              Address Info
            </Typography>
            <Grid container spacing={2} style={{padding:'15px'}}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Permanent Address:</Typography>
                <Typography variant="body2">
                  {editStudentAddress.pProvince}, {editStudentAddress.pDistrict}, {editStudentAddress.pLocalLevel}, Ward No {editStudentAddress.pWardNo}, House No {editStudentAddress.pHouseNo || '-'}, Tole {editStudentAddress.pTole || '-'}
                </Typography>
              </Grid>
              {!editStudentAddress.isSameAsPermament && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Temporary Address:</Typography>
                  <Typography variant="body2">
                    {editStudentAddress.tProvince}, {editStudentAddress.tDistrict}, {editStudentAddress.tLocalLevel}, Ward No {editStudentAddress.tWardNo}, House No {editStudentAddress.tHouseNo || '-'}, Tole {editStudentAddress.tTole || '-'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button onClick={handleBack} sx={{ color: '#2b6eb5' }} type="reset" size="small"
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{
          bgcolor: "#2b6eb5",
          marginBottom: "10px",
          marginLeft: "10px",
          position: 'relative', // To position the loader correctly
          // Disable the button while loading
          pointerEvents: loading ? 'none' : 'auto',
          opacity: loading ? 0.6 : 1,
        }}
        onClick={handleRegister}
        endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ChevronRightRoundedIcon />}
        >
          {loading ? 'Submitting...' : 'Update & Next'}
        </Button>
      </Box>
    </>
  );
};

export default EditReviewDetails;
