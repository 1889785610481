import React, { createContext, useContext, useEffect, useState } from "react";
import { Grid, TextField, Button, Box } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getUniversityById } from "../../../services/services";

const EditUniversityContactContext = createContext();

const EditUniversityContactProvider = ({ children }) => {
  const methods = useForm();
  const [contactDetails, setContactDetails] = useState({
    yearOfEstd: "",
    dateOfEstd: 0,
    accreditationStatus: "",
    focalPersonName: "",
    position: "",
    contactNumber: "",
    contactEmail: "",
    bankName: "",
    branchName: "",
    accountNumber: "",
  });

  // Update the contactDetails state as the form data changes
  const onChange = (name, value) => {
    setContactDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <EditUniversityContactContext.Provider
      value={{ ...methods, contactDetails, onChange }}
    >
      {children}
    </EditUniversityContactContext.Provider>
  );
};

const EditContactDetails = ({ handleNext, handleBack, id }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useContext(EditUniversityContactContext);
  const { onChange } = useContext(EditUniversityContactContext);
  const [setUniversityById] = useState([]);

  const fetchData = async () => {
    try {
      const getUniversityData = await getUniversityById(id);
      
      setValue("yearOfEstd", getUniversityData.yearOfEstd);
      setValue("dateOfEstd", getUniversityData.dateOfEstd);
      setValue("accreditationStatus", getUniversityData.accreditationStatus);
      setValue("focalPersonName", getUniversityData.focalPersonName);
      setValue("position", getUniversityData.position);
      setValue("contactNumber", getUniversityData.contactNo);
      setValue("contactEmail", getUniversityData.contactEmail);
      setValue("bankName", getUniversityData.bankName || '');
      setValue("branchName", getUniversityData.branch || "");
      setValue("accountNumber", getUniversityData.accountNo || '');
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    fetchData();
  }, [id, setValue]);

  const onSubmit = (data) => {
    onChange("yearOfEstd", data.yearOfEstd);
    onChange("dateOfEstd", data.dateOfEstd);
    onChange("accreditationStatus", data.accreditationStatus);
    onChange("focalPersonName", data.focalPersonName);
    onChange("position", data.position);
    onChange("contactNumber", data.contactNumber);
    onChange("contactEmail", data.contactEmail);
    onChange("bankName", data.bankName);
    onChange("branchName", data.branchName);
    onChange("accountNumber", data.accountNumber);
    handleNext();
  };

  const onBack = (data) => {
    console.log(data);
    handleBack(); // Call handleNext only if form submission is successful
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid mt="1rem" container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Controller
            name="yearOfEstd"
            control={control}
            rules={{
              required: "Estd. year required",
              pattern: {
                value: /^\d{0,4}$/,
                message:
                  "Please enter only numeric values with maximum length of four digits",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="yearOfEstd"
                size="small"
                type="text"
                label="Year Of Establishment"
                name="yearOfEstd"
                InputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, ""); // Allow only numeric characters
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4); // Limit input to maximum of four characters
                    }
                  },
                }}
                fullWidth
                error={!!errors.yearOfEstd}
                helpertext={errors.yearOfEstd ? errors.yearOfEstd.message : ""}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="dateOfEstd"
            control={control}
            rules={{ required: "Date is required" }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type="date" // Change type to date
                size="small"
                label="Date of establishment"
                error={!!errors.dateOfEstd}
                fullWidth
                helpertext={
                  errors.dateOfEstd ? "Date of establishment required" : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="accreditationStatus"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="accreditationStatus"
                size="small"
                name="accreditationStatus"
                label="Accreditation Status"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="focalPersonName"
            control={control}
            rules={{ required: "Full Name is required" }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="focalPersonName"
                size="small"
                type="text"
                name="focalPersonName"
                label="Contact Person Name"
                fullWidth
                error={!!errors.focalPersonName}
                helpertext={errors.focalPersonName ? "Full name required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="position"
            control={control}
            rules={{ required: "Position is required" }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="position"
                size="small"
                name="position"
                label="Position"
                fullWidth
                error={!!errors.position}
                helpertext={errors.position ? "Position is required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="contactNumber"
            control={control}
            rules={{
              required: "contactNumber is required",
              validate: (value) => {
                const isValidPhone = /^[9]\d{9}$/.test(value);
                return isValidPhone || "invalid phone umber";
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="contactNumber"
                size="small"
                name="contactNumber"
                label="Contact Number"
                InputProps={{
                  placeholder: "98XXXXXXXX",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10); // Limit input to 10 characters
                  },
                }}
                fullWidth
                error={!!errors.contactNumber}
                helpertext={
                  errors.contactNumber ? errors.contactNumber.message : ""
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="contactEmail"
            control={control}
            rules={{
              required: "contactEmail is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Email format not matched",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="contactEmail"
                size="small"
                name="contactEmail"
                label="Contact Email"
                fullWidth
                error={!!errors.contactEmail}
                helpertext={errors.contactEmail && errors.contactEmail.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="bankName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="bankName"
                size="small"
                name="bankName"
                label="Bank Name"
                fullWidth
               
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="branchName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="branchName"
                name="branchName"
                size="small"
                label="Bank Branch"
                fullWidth
               
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="accountNumber"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="accountNumber"
                name="accountNumber"
                size="small"
                label="Bank Account Number"
                fullWidth
               
              />
            )}
          />
        </Grid>
      </Grid>
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          variant="standard"
          size="small"
          type="reset"
          sx={{
            color: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{
            bgcolor: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};
export { EditUniversityContactProvider, EditUniversityContactContext };
export default EditContactDetails;
